import React, { useState } from 'react';
import styles from './index.module.less';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

const PhotoGuideInnerContent = ({
  borderColor,
  title,
  desc,
  imgPath,
}: {
  borderColor: string;
  title: string;
  desc: string;
  imgPath?: string;
}) => {
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <div className={styles.content} style={{ border: `1px solid ${borderColor}` }}>
      <div className={styles.title}>{title}</div>
      <div className={styles.desc}>{desc}</div>
      <img src={imgPath} alt="" />
    </div>
  );
};

export default PhotoGuideInnerContent;
