import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.less';
import {
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import copy from 'clipboard-copy';
import { App, Modal, Space, Tooltip } from 'antd';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { DownOutlined } from '@ant-design/icons';
import { HairCreateResultModel, HairShareInfoModel } from '@/types/hairstyle';
import { imgUrl } from '@/shared/utils';
import { fetchHairstyleCreateShareData } from '@/lib/service';

const HairShareAlertContent = ({
  openType,
  isGenerate = false,
  dataList,
  yourImage,
  handleCancel,
}: {
  openType?: any;
  isGenerate?: boolean;
  dataList?: HairCreateResultModel[];
  yourImage?: string;
  handleCancel?: () => void;
}) => {
  const whatsappRef = useRef(null);
  const facebookRef = useRef(null);
  const twitterRef = useRef(null);
  const pinterestRef = useRef(null);

  const { message } = App.useApp();
  const { t } = useTranslation('common');

  const title = `Wow, great new hairstyle! It's so easy and fun to see new looks, Try it for free and find your style!`;
  const insTitle = `Super cool trendy hairstyles!`;
  const insDesc = `Just played around with this super cool website and can't decide on my new look! 🤔 I've tried on several hairstyles and here's how I look. Which one do you think fits me the best? Your vote will help me make the big decision. Drop your favorite in the comments! `;

  const [currentDayData, setCurrentDayData] = useState({
    label: '7 Day',
    value: '7',
  });
  const [currentType, setCurrentType] = useState(null);

  const [selectYouImage, setSelectYouImage] = useState<string>(null);
  const [selectDataList, setSelectDataList] = useState<HairCreateResultModel[]>([]);
  const [shareInfoModel, setShareInfoModel] = useState<HairShareInfoModel>(null);

  useEffect(() => {
    if (openType != undefined) {
      setSelectYouImage(yourImage);
      if (dataList?.length >= 3) {
        setSelectDataList([...dataList.slice(0, 3)]);
      } else {
        setSelectDataList([...(dataList ?? [])]);
      }
    }
  }, [openType]);

  useEffect(() => {
    if (shareInfoModel !== null) {
      switch (currentType) {
        case 'whatsapp':
          if (whatsappRef.current) {
            whatsappRef.current.click();
          }
          break;
        case 'facebook':
          if (facebookRef.current) {
            facebookRef.current.click();
          }
          break;
        case 'x':
          if (twitterRef.current) {
            twitterRef.current.click();
          }
          break;
        case 'pinterest':
          if (pinterestRef.current) {
            pinterestRef.current.click();
          }
          break;
        case 'copy':
          handleCopyAction();
          break;

        default:
          break;
      }
    }
  }, [shareInfoModel]);

  const loadShareResult = (type: string) => {
    setCurrentType(type);

    let tempParams: any;
    if (isGenerate) {
      tempParams = {
        your_image: yourImage,
        create_ids: selectDataList?.map((item) => item?.create_id),
        expired_days: currentDayData?.value,
      };
    } else {
      tempParams = {
        create_ids: dataList?.map((item) => item?.create_id),
        expired_days: currentDayData?.value,
      };
    }

    fetchHairstyleCreateShareData(tempParams)
      .then((res) => {
        if (res?.code === 0) {
          setShareInfoModel(res?.data);
        } else {
          message?.error(res?.message);
        }
      })
      .catch((e) => {
        message?.error(e?.message);
      });
  };

  const getShareUrl = () => {
    return `${process.env.NEXT_APP_WEB_HOST}/share/${shareInfoModel?.link_id}`;
  };

  const handleCopyAction = async () => {
    try {
      await copy(getShareUrl());
      message.success(t('URLCopiedToClipboard'));
    } catch (err) {
      console.error('Unable to copy text to clipboard:', err);
    }
  };

  const daysList = [
    {
      label: '1 Day',
      value: '1',
    },
    {
      label: '3 Day',
      value: '3',
    },
    {
      label: '7 Day',
      value: '7',
    },
    {
      label: '15 Day',
      value: '15',
    },
    {
      label: '30 Day',
      value: '30',
    },
    {
      label: 'Never',
      value: '-1',
    },
  ];

  const titleContent = () => (
    <div className={styles.valueListContainer}>
      {daysList.map((item) => {
        return (
          <div
            key={item.value}
            style={{
              color:
                item?.value === currentDayData?.value
                  ? 'var(--app-primary-color)'
                  : 'var(--other-text-color)',
            }}
            onClick={(e) => {
              setCurrentDayData(item);
              // console.log(item?.value);
              e.preventDefault();
            }}
          >
            {item?.label}
          </div>
        );
      })}
    </div>
  );
  return (
    <Modal
      className={styles.groundContent}
      title={
        isGenerate ? <div style={{ textAlign: 'center' }}>{t('Pick Your Best Look')}</div> : <></>
      }
      open={openType}
      onCancel={handleCancel}
      okButtonProps={{ disabled: true }}
      cancelButtonProps={{ disabled: true }}
      footer={null}
      maskClosable={false}
      getContainer={() => document.body}
      destroyOnClose
    >
      <div className={styles.mainContainer}>
        <div style={{ textAlign: 'left', fontSize: 20, fontWeight: 900 }}>
          {t('Show Everyone Your New Look!')}
        </div>
        {isGenerate && (
          <div>
            {`Great! You just created your perfect hairstyle. Share it with friends to help you pick the best look. Your new style is just a share away!`}
          </div>
        )}

        {isGenerate && (
          <ul className={styles.imagesList}>
            <li
              onClick={() => {
                if (selectDataList?.length > 3 && selectYouImage !== yourImage) {
                  message?.error(t('Oops! Limit your share to 4 photos, please.'));
                  return;
                }
                setSelectYouImage(selectYouImage === yourImage ? null : yourImage);
              }}
            >
              <img src={yourImage} alt="" />
              <p>{`Your Photo`}</p>
              <Image
                className={styles.selectIcon}
                src={
                  selectYouImage === yourImage
                    ? '/images/hairstyle/image_select.png'
                    : '/images/hairstyle/image_unselect.png'
                }
                alt={''}
                width={16}
                height={16}
              />
            </li>

            {(dataList ?? [])?.map((item, index) => {
              return (
                <li
                  key={`${item?.create_id}-${index}`}
                  onClick={() => {
                    if (
                      (selectDataList?.length > 3 && selectYouImage !== yourImage) ||
                      (selectDataList?.length > 2 && selectYouImage === yourImage)
                    ) {
                      message?.error(t('Oops! Limit your share to 4 photos, please.'));
                      return;
                    }

                    if (selectDataList?.includes(item)) {
                      setSelectDataList(
                        selectDataList?.filter(
                          (tempItem) => tempItem?.create_id !== item?.create_id,
                        ),
                      );
                    } else {
                      setSelectDataList([...selectDataList, item]);
                    }
                  }}
                >
                  <img src={imgUrl(item?.result_pic ?? '', 'mid')} alt="" />
                  <p>{item?.hairstyle?.name}</p>
                  <Image
                    className={styles.selectIcon}
                    src={
                      selectDataList?.filter((tempItem) => tempItem?.create_id === item?.create_id)
                        ?.length > 0
                        ? '/images/hairstyle/image_select.png'
                        : '/images/hairstyle/image_unselect.png'
                    }
                    alt={''}
                    width={16}
                    height={16}
                  />
                </li>
              );
            })}
          </ul>
        )}

        <div className={styles.shareTitleContainer}>
          <span className={styles.shareTitle}>{`Share to:`}</span>

          <Tooltip title={titleContent} color={'#FFF'} placement="bottomRight">
            <span className={styles.shareValue}>
              <Space>
                {`Link Expiration: ${currentDayData?.label}`}
                <DownOutlined />
              </Space>
            </span>
          </Tooltip>
        </div>

        <div className={styles.container}>
          <div className={styles.shareItemContainer} onClick={() => loadShareResult('whatsapp')}>
            <Image
              src={'/images/share/share-whats-1.png'}
              alt="Whats"
              loading="lazy"
              width={30}
              height={30}
            />
            <p>{`Whatsapp`}</p>
          </div>

          <div className={styles.shareItemContainer} onClick={() => loadShareResult('facebook')}>
            <Image
              src={'/images/share/share-fb-1.png'}
              alt="Facebook"
              loading="lazy"
              width={30}
              height={30}
            />
            <p>{`Facebook`}</p>
          </div>

          <div className={styles.shareItemContainer} onClick={() => loadShareResult('x')}>
            <Image
              src={'/images/share/share_twitter.png'}
              alt="X"
              loading="lazy"
              width={30}
              height={30}
            />
            <p>{`X`}</p>
          </div>

          <div className={styles.shareItemContainer} onClick={() => loadShareResult('pinterest')}>
            <Image
              src={'/images/share/share-pin-1.png'}
              alt="Pinterest"
              loading="lazy"
              width={30}
              height={30}
            />
            <p>{`Pinterest`}</p>
          </div>

          <div className={styles.shareItemContainer} onClick={() => loadShareResult('copy')}>
            <Image
              src={'/images/share/share-copy-1.png'}
              alt="Copy Link"
              loading="lazy"
              width={30}
              height={30}
            />
            <p>{`Copy Link`}</p>
          </div>

          <WhatsappShareButton
            ref={whatsappRef}
            style={{ display: 'none' }}
            url={getShareUrl()}
            title={title}
          >
            <></>
          </WhatsappShareButton>

          <FacebookShareButton
            ref={facebookRef}
            style={{ display: 'none' }}
            url={getShareUrl()}
            title={title}
          >
            <></>
          </FacebookShareButton>

          <TwitterShareButton
            ref={twitterRef}
            style={{ display: 'none' }}
            title={title}
            url={getShareUrl()}
          >
            <></>
          </TwitterShareButton>

          <PinterestShareButton
            ref={pinterestRef}
            style={{ display: 'none' }}
            url={getShareUrl()}
            title={insTitle}
            description={insDesc}
            // pinId={`hairstyles、Simple Hairstyles`}
            media={shareInfoModel?.image_url}
          >
            <></>
          </PinterestShareButton>
        </div>
      </div>
    </Modal>
  );
};

export default HairShareAlertContent;
