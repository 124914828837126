import React, { useState } from 'react';
import styles from './index.module.less';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import PhotoGuideInnerContent from './PhotoGuideInnerContent';
import { Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

const PhotoGuideContent = ({ open, handleClose }: { open?: boolean; handleClose?: () => void }) => {
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <Modal
      className={styles.groundContent}
      open={open}
      closeIcon={<CloseOutlined style={{ color: 'var(--app-text-color)' }} />}
      onCancel={handleClose}
      okButtonProps={{ disabled: true }}
      cancelButtonProps={{ disabled: true }}
      footer={null}
      maskClosable={false}
      style={{ padding: 0 }}
      destroyOnClose={true}
    >
      <div className={styles.content}>
        <div className={styles.innerContent}>
          <div className={styles.title}>{`Photo Guide`}</div>

          <div className={styles.demoContent}>
            <div>
              <PhotoGuideInnerContent
                borderColor="#99FFA9"
                title={t('Good to Go')}
                desc={t(
                  'Clear, front-facing photos showing all facial features with good lighting.',
                )}
                imgPath="/images/hairstyle/example-ok-icon.png"
              />
            </div>

            <div>
              <PhotoGuideInnerContent
                borderColor="#FA574D"
                title={t('Not Suitable')}
                desc={t(
                  'Side views, multiple faces, or unrecognizable faces, and poorly lit photos.',
                )}
                imgPath="/images/hairstyle/example-error-icon.png"
              />
            </div>
          </div>

          <div className={styles.desc}>
            {`Uploading high-quality photos will produce better results.`}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PhotoGuideContent;
